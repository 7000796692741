import React from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import FullImageBanner from "../components/components/FullImageBanner/FullImageBanner";
import ContactForm from "../components/components/ContactForm/ContactForm";
import Numbers, { Number } from "../components/components/Numbers/Numbers";
import ContactLineCTA from "../components/components/ContactLineCTA/ContactLineCTA";
import { ContainerRow } from "../components/atoms/Containers/Conainers";
import UnderlineTitle from "../components/atoms/UnderlineTitle/UnderlineTitle";
import Text from "../components/atoms/Text/Text";
import Herobanner from "../components/elements/Herobanner/Herobanner";
import { scrollToContact } from "../utility/function";

const Affiliati = () => (
  <Page title='Affiliati' description='Hai un blog, database o presenza social? Partecipa alle nostre campagne finance e genera un flusso costante di commissioni' image='/favicon.png'>
    <Layout navigationCallback={() => scrollToContact()}>
      <Herobanner backgroundUrl={"/herobanner/affiliati.svg"}>
        <h1 className='mb-3'>Diventa nostro publisher</h1>
        <Text>Hai un blog, database o presenza social? Partecipa alle nostre campagne e genera un flusso costante di commissioni</Text>
        <Button customClass='mt-6' onClick={() => scrollToContact()}>
          Contattaci
        </Button>
      </Herobanner>

      <Space mobile={10} table={15} desktop={20} />

      <Numbers text='Monetizza ora le tue properties, entra a far parte del nostro network' icon='statistic'>
        <Number number={"50+"} text={"Campagne mensili"} />
        <Number number={"500k+"} text={"Conversioni generate"} />
        <Number number={"500+"} text={"Publishers"} />
      </Numbers>

      <Space mobile={10} table={4} desktop={10} />

      {/* 
        text: Monetizza ora le tue properties, entra a far parte del nostro network  
        100+ publishers
        50+ advertisers
        30+ campagne attive
      */}

      <ContactLineCTA callback={() => scrollToContact()} />

      <Space mobile={10} table={4} desktop={20} />

      <FullImageBanner image='/background/full-image-affiliate.jpg' ctaLine1='Siamo un gruppo affiatato.' ctaLine2='Unisciti a noi.' />

      <Space mobile={20} table={20} desktop={20} />

      <ContainerRow>
        <div className='col-12 col-md-6'>
          <UnderlineTitle>Le nostre campagne</UnderlineTitle>
          <Text>
            Collaboriamo con i principali player finanziari sul territorio nazionale per proporti campagne altamente performanti su vari verticali: finanziamenti, conti correnti, noleggio a lungo
            termine, assicurazioni. Le nostre condizioni di pay e di liquidazione delle commissioni sono tra gli elementi che i nostri publisher apprezzano di più.
          </Text>
        </div>
        <div className='col-12 col-md-6 mt-8 mt-md-0'>
          <UnderlineTitle>Il network</UnderlineTitle>
          <Text>
            Siamo un fit ideale per te se possiedi database profilati in ambito finance, se hai un blog o properties con contenuti in tema finanziario ed automotive, o ancora se hai una presenza sui
            social con brand affini alle nostre campagne. Con noi avrai la massima trasparenza su ogni campagna.
          </Text>
        </div>
      </ContainerRow>

      <Space mobile={20} table={20} desktop={20} />

      <ContactForm />
    </Layout>
  </Page>
);

export default Affiliati;
